dmx.Component('browser', {

    initialData: {
        online: navigator.onLine,
        doNotTrack: false,
        userAgent: navigator.userAgent,
        language: navigator.language,
        cookieEnabled: navigator.cookieEnabled,
        referrer: document.referrer,

        location: {
            hash: window.location.hash,
            host: window.location.host,
            hostname: window.location.hostname,
            href: window.location.href,
            origin: window.location.origin,
            pathname: window.location.pathname,
            port: window.location.port,
            protocol: window.location.protocol,
            search: window.location.search,
            pathparts: window.location.pathname.substr(1).split('/')
        },

        scrollX: {
            offset: 0,
            direction: 0,
            position: 0
        },

        scrollY: {
            offset: 0,
            direction: 0,
            position: 0
        },

        viewport: {
            width: 0,
            height: 0
        },

        device: {
            width: 0,
            height: 0,
            pixelRatio: 1,
            orientation: 'landscape'
        },

        document: {
            width: 0,
            height: 0,
            hidden: document.hidden,
            visibility: document.visibilityState
        }
    },

    methods: {
        goto: function(url) {
            if (url[0] == '.' && url[1] == '/') {
                var base = document.querySelector('meta[name="ac:base"]');
                var route = document.querySelector('meta[name="ac:route"]');

                if (route && route.content) {
                    var path = route.content;

                    if (base && base.content) {
                        path = base.content.replace(/\/$/, '') + path;
                    }

                    var match = dmx.pathToRegexp(path, [], { end: false }).exec(location.pathname);

                    if (match) {
                        window.history.pushState(null, '', url.replace('./', match[0]));
                        dmx.requestUpdate();

                        var event = document.createEvent('Event');
                        event.initEvent('pushstate', false, false);
                        window.dispatchEvent(event);
                        return;
                    }
                }
            }

            window.location = url;
        },

        scrollTo: function(x, y) {
            window.scrollTo(x, y);
        },

        scrollXTo: function(pos) {
            window.scrollTo({ left: pos });
        },

        scrollYTo: function(pos) {
            window.scrollTo({ top: pos });
        },

        scrollBy: function(x, y) {
            window.scrollBy(x, y);
        },

        scrollXBy: function(delta) {
            window.scrollBy({ left: delta });
        },

        scrollYBy: function(delta) {
            window.scrollBy({ top: delta });
        },

        alert: function(message) {
            window.alert(message);
        }
    },

    render: function(node) {
        window.addEventListener('scroll', this.update.bind(this));
        window.addEventListener('resize', this.update.bind(this));
        window.addEventListener('online', this.update.bind(this));
        window.addEventListener('offline', this.update.bind(this));
        window.addEventListener('popstate', this.update.bind(this));
        window.addEventListener('visibilitychange', this.update.bind(this));
        if (window.orientation) window.addEventListener('orientationchange', this.update.bind(this));

        this.update();
    },

    update: function(event) {
        var docWidth = Math.max(document.body.scrollWidth, document.documentElement.scrollWidth);
        var docHeight = Math.max(document.body.scrollHeight, document.documentElement.scrollHeight);

        var scrollDirectionX = this.data.scrollX.direction;
        var scrollDirectionY = this.data.scrollY.direction;

        if (event && event.type == 'scroll') {
            if (this.data.scrollX.offset < window.pageXOffset) {
                scrollDirectionX = 1;
            } else if (this.data.scrollX.offset > window.pageXOffset) {
                scrollDirectionX = -1;
            }

            if (this.data.scrollY.offset < window.pageYOffset) {
                scrollDirectionY = 1;
            } else if (this.data.scrollY.offset > window.pageYOffset) {
                scrollDirectionY = -1;
            }
        }

        this.set('online', navigator.onLine);
        this.set('doNotTrack', navigator.doNotTrack == '1' || window.doNotTrack == '1' || navigator.msDoNotTrack == '1');
        this.set('userAgent', navigator.userAgent);
        this.set('language', navigator.language);
        this.set('cookieEnabled', navigator.cookieEnabled);
        this.set('referrer', document.referrer);

        this.set('location', {
            hash: window.location.hash,
            host: window.location.host,
            hostname: window.location.hostname,
            href: window.location.href,
            origin: window.location.origin,
            pathname: window.location.pathname,
            port: window.location.port,
            protocol: window.location.protocol,
            search: window.location.search,
            pathparts: window.location.pathname.substr(1).split('/')
        });

        this.set('scrollX', {
            offset: window.pageXOffset,
            length: Math.max(0, docWidth - window.innerWidth),
            direction: scrollDirectionX,
            position: window.pageXOffset > 0 ? window.pageXOffset / (docWidth - window.innerWidth) : 0
        });

        this.set('scrollY', {
            offset: window.pageYOffset,
            length: Math.max(0, docHeight - window.innerHeight),
            direction: scrollDirectionY,
            position: window.pageYOffset > 0 ? window.pageYOffset / (docHeight - window.innerHeight) : 0
        });

        this.set('viewport', {
            width: window.innerWidth,
            height: window.innerHeight,
            scrollX: window.pageXOffset,
            scrollY: window.pageYOffset
        });

        this.set('device', {
            width: window.screen.width,
            height: window.screen.height,
            pixelRatio: window.devicePixelRatio || window.screen.deviceXDPI / window.screen.logicalXDPI,
            orientation: this.getOrientation()
        });

        this.set('document', {
            width: docWidth,
            height: docHeight,
            hidden: document.hidden,
            visibility: document.visibilityState
        });
    },

    getOrientation: function() {
        if (window.matchMedia("(orientation: portrait)").matches) {
            return 'portrait';
        } else {
            return 'landscape';
        }
    }

});
